import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Button from './Button'

const Wrapper = styled.section`
  position: relative;
  /* // margin-top: 90px; */
  min-height: 200px;
  margin-bottom: 1em;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  
  .partner-content {
    width: 100%;
  }

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
`

const Col = styled.div`
  &.col-medium {
    width: 65%;
    padding: 0 2em;
    display: flex;
    flex-direction: column;
  }
  &.col-small {
    width: 35%;
    padding: 0 2em;
    display: flex;
    flex-direction: column;
  }

  &.col-small {
    &.button-content {
      width: fit-content;

      a {
        text-decoration: none;
      }
    }
  }

  &.col-medium {
    h3 {
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 35px;
      letter-spacing: 0.18em;
      color: ${props => (props.tertiary ? props.theme.colors.tertiary : props.theme.colors.black)};
      margin-bottom: 2em;
    }

    h3 .partener-email {
      font-weight: 600; 
    }

    p {
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;
      letter-spacing: 0.14em;
      margin-bottom: 2em;
    }

    .content-logos {
      display: flex;
      align-items: center;
      padding-bottom: 27px;

      & .plus-separator {
        width: 34px;
        height: 40px;
        font-family: Raleway;
        font-style: normal;
        font-weight: normal;
        font-size: 50px;
        line-height: 40px;
        /* identical to box height, or 80% */

        text-align: center;
        letter-spacing: 0.14em;
        color: #000000;
        margin: 0 40px;
      }
    }

    &.button-content {
      width: fit-content;

      a {
        text-decoration: none;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    &.col-medium {
      h3 {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.0727273px;
      }
      p {
        font-size: 12;
        line-height: 25px;
      }
    }
  }
  @media screen and (max-width: 800px) {
    &.col-medium {
      width: 100%;
    }
    &.col-small {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    .content-logos {
      margin-top: 2em;
      justify-content: center;
    }

    &.col-small {
      h3 {
      }
      p {
      }
    }

    &.col-medium {
      h3 {
      }
      p {
      }
    }
  }

  @media screen and (max-width: 600px) {
    &.col-medium {
      width: 100%;
      padding: 0 0.5em;
    }
    &.col-small {
      margin: 3em 0 1.5em 0;
      padding: 0 0.5em;
      width: 100%;
    }

    &.col-small {
      h3 {
      }
      p {
      }
    }

    &.col-medium {
      h3 {
      }
      p {
      }
    }
  }
`

const Row = styled.section`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 1em auto;
  padding-bottom: 6em;
  max-width: ${props => props.theme.sizes.maxNavWidth};
  padding-top: 1em;
  justify-content: center;
  
  &.row-right {
    flex-direction: row-reverse;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    &.row-right {
      flex-direction: column;
    }
    padding: 1em 0 4em;
  }

  @media screen and (max-width: 600px) {
    padding: 1em 0 4em;
  }

  @media screen and (max-width: 700px) {
    padding: 1em 0 2em;
  }
`

const RowSeparator = styled.div`
  margin: 1em 0;
  border-bottom: 1px solid  ${props => props.theme.colors.lightGrey};
  width: 93%;
  &.row-separator {
    margin-bottom: 5em;
  }
`

const PartnerImg = styled(Img)`
  height: auto;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
`

const PartnerLogo = styled(Img)`
  width:  100%;
  max-width: 200px;
  height: auto;
`

const BrandLogo = styled(Img)`
  width:  100%;
  max-width: 250px;
  height: auto;
`

const PartneshipList = props => {
  return (
    <Wrapper padding={props.contentPadding}>
      {props.partnerships && props.partnerships.map((partner, index) => 
        <div className="partner-content" key={`partneship-${index}`}>
          <Row className={ props.contentPosition ? `row-${props.contentPosition}`: 'row'} maxWidth={props.maxWidth}>
            <Col className={"col-small"}>
              <PartnerImg fluid={partner.image.fluid}/>
            </Col>
            <Col 
              className={"col-medium"}
              tertiary
            >
              <div className="content-logos">
                <PartnerLogo fluid={partner.logo.fluid}/>
                <span className="plus-separator">
                  +
                </span>
                <BrandLogo fluid={props.brandLogo.fluid}/>
              </div>
              {(partner.description && partner.description.childMarkdownRemark) &&
                <div
                  dangerouslySetInnerHTML={{
                    __html: partner.description.childMarkdownRemark.html || ''
                  }}
                />
              }
              <h3>Contact <span className="partener-email">{partner.contactEmail || 'hello@embello.com'}</span> for more information.</h3>
              {partner.buttonLabel && 
                <div className="button-content">
                  <a 
                    href={partner.buttonAction || "mailto: hello@embello.com"} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    <Button 
                      padding="18.5px 44px" 
                      borderRadius="6.28832px"
                      className="tertiary large">
                      {partner.buttonLabel || 'CONTACT US'}
                    </Button>
                  </a>
                </div>
              }
            </Col>
          </Row>
          {(props.partnerships.length !== (index + 1)) &&
            <RowSeparator className={index % 2 ? '' : 'row-separator'}/>
          }
        </div>
      )}
    </Wrapper>
  )
}

export default PartneshipList
