import React from 'react'
import Layout from '../components/Layout'
import HeroBasic from '../components/HeroBasic'
import styled from 'styled-components'
import PartneshipList from '../components/PartneshipList'

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  .button-content {
    width: fit-content;

    & a {
      text-decoration: none;
    } 
  }

  @media screen and (max-width: 700px) {
    padding: 0 1.5em;
  }
`

const PartnershipsPage = ({ data, pageContext }) => {

  const contentPage = data.contentfulPartnershipsPage

  let layoutRef = React.createRef();

  return (
    <Layout ref={layoutRef} whiteNav>
      <HeroBasic 
        image={contentPage.heroImage}
        title={contentPage.title}
        contentPosition="left"
        heroBackground="rgba(255, 255, 255, 0.7)"
        contentWidth="70"
        titleSize="45px"
      />
      <Wrapper>
        <PartneshipList
          partnerships={contentPage.partnerships}
          brandLogo={contentPage.brandLogo}
        />
      </Wrapper>
      
    </Layout>
  )
  
}

export const query = graphql`
  query {
    contentfulPartnershipsPage(version: { eq: "current" }) {
      title
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      brandLogo {
        title
        fluid(maxWidth: 250) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      partnerships {
        title
        logo {
          title
          fluid(maxWidth: 200) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        image {
          title
          fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        description {
          childMarkdownRemark {
            html
          }
        }
        contactEmail
        buttonLabel
        buttonAction
      }
    }
  }
`

export default PartnershipsPage
